let searchFilter = function(load) {
	// this.InvoicesList[i].accountSMS = window.accountSMS
	let t = this;
	t.validJobsApp();
	if(load==undefined){
		t.isLoading = true;
	}
	let hasta = "";
	let desde = "";
	let duehasta = "";
	let duedesde = "";
	if (t.searchDate) {
		desde = t.dateFrom;
		hasta = t.dateTo;
		duedesde = t.duedateFrom;
		duehasta = t.duedateTo;
		t.searchDate = false;
	}
	let jobs = 0;
	if(t.accessJobsApp){jobs=1;}
	let statusFill = t.statuslist.filter(r => {return r});
	if(t.statuslist.includes("4") && !t.statuslist.includes("1")){statusFill.push('1');}
	window.billing.post("filterinvoice" + t.query, {
		user_id: window.localuserdata,
		filter: t.inputFilter.trim(),
		fieldsFilter: t.fieldsFilter,
		status: statusFill,
		pag: t.paginador,
		rowNum: t.rowNum,
		desde: desde,
		hasta: hasta,
		duedesde: duedesde,
		duehasta: duehasta,
		jobs: jobs,
	})
	.then((response) => {
		t.cancelSearchDate();
		t.pagination = response.data.lista;
		//console.log('filterinvoice', response.data.lista.data)
		let listFilter = Object.values(response.data.lista.data);
		let e = listFilter;
		let listOverdue = [];
		let listStatus = [];
		let soloFilter = [];
		t.Actionsdropd = [];
		for (var i = 0; i < e.length; i++) {
			t.Actionsdropd[e[i].id] = false;
			let fecdoc = new Date(e[i].fecdoc).toDateString();
			let fecdue = new Date(e[i].fecdue).toDateString();
			let f_a = new Date();
			fecdue = new Date(e[i].fecdue);
			listFilter[i].actions = false;
			listFilter[i].job = {id:0};
			listFilter[i].balance = listFilter[i].total - listFilter[i].abono;
			if (f_a > fecdue && e[i].idxstatus < 2) {
				listFilter[i].idxstatus = "4";
				listOverdue.push(listFilter[i]);
				// console.log('overdue add', listFilter[i])
			} else {
				listStatus.push(listFilter[i]);
				// console.log('otro status add', listFilter[i])
			}
			if (t.statuslist.includes(listFilter[i].idxstatus.toString()) || t.statuslist.includes(parseInt(listFilter[i].idxstatus))) {
				soloFilter.push(listFilter[i]);
				// console.log('add soloFilter', listFilter[i].idxstatus, t.statuslist)
			}else{
				// console.log('excluir status', listFilter[i].idxstatus)
				// debugger;
			}
		}
		console.log('condicion 1', (t.statuslist.length == 1 && t.statuslist.includes("4") && t.statuslist.includes(4)))
		console.log('condicion 2', (t.statuslist.length > 0 && t.statuslist.length < 5 && (t.statuslist.includes("4") || t.statuslist.includes(4))))
		console.log('condicion 3', (t.statuslist.length > 0 && t.statuslist.length < 5 && (!t.statuslist.includes("4") && !t.statuslist.includes(4))))
		// debugger;
		if (t.statuslist.length == 1 && (t.statuslist.includes("4") || t.statuslist.includes(4))) {
			t.InvoicesList = listOverdue;
		} else if (
			t.statuslist.length > 0 &&
			t.statuslist.length < 5 &&
			(t.statuslist.includes("4") || t.statuslist.includes(4))
			) {
			t.InvoicesList = soloFilter;
			console.log('soloFilter', soloFilter.length)
		} else if (
			t.statuslist.length > 0 &&
			t.statuslist.length < 5 &&
			(!t.statuslist.includes("4") && !t.statuslist.includes(4))
			) {
			t.InvoicesList = listStatus;
			console.log('sin overdue', t.statuslist)
			console.log('listStatus', listStatus.length)
		} else {
			console.log('listStatus All', t.statuslist)
			t.InvoicesList = listFilter;
		}
		if (
			t.inputFilter.length > 0 ||
			t.statuslist.length != 5 ||
			desde.length > 0 ||
			hasta.length > 0 ||
			duedesde.length > 0 ||
			duehasta.length > 0
		) {
			t.isFilter = true;
		}
		if(response.data.setting_columnas){
			let cols = response.data.setting_columnas.meta_value;
			if(cols){
				t.showCols(cols);
			}
		}
		t.InvoicesListResult = t.InvoicesList;
		// console.log('InvoicesList', t.InvoicesList)
		t.isLoading = false;
		t.numPagList();
		if(t.accessJobsApp){
			t.jobsinvo();
		}
	})
	.catch((error) => {
		t.isLoading = false;
		console.log(error);
	});
};
export default searchFilter;
