<template>
    <div>
        <aside
            class="left-sidebar"
            data-sidebarbg="skin5"
            v-bind:class="{ min: activarLateral }"
        >
            <div class="scroll-sidebar">
                <nav class="sidebar-nav">
                    <ul id="sidebarnav">
                        <li
                            class="sidebar-item"
                            v-for="(i, index) in menuBilling"
                            v-bind:key="index"
                            v-bind:class="{ sub: (i.type === 2 || i.type === 3 || i.type === 4 ) }"
                        >
                            <router-link
                                :to="i.ruta"
                                exact-active-class="active"
                                class="sidebar-link waves-effect waves-dark"
                                v-if="i.type == 1 && (optionsPremium.indexOf(i.m_id)==-1)"
                            >
                                <i :class="i.icon"></i>
                                <span class="hide-menu">{{ i.name }}</span>
                            </router-link>
                            <a href="#"
                                v-else-if="i.type == 1 && optionsPremium.indexOf(i.m_id)>-1"
                                @click.prevent="optPremium()"
                                exact-active-class="active"
                                class="sidebar-link waves-effect waves-dark optPremium"
                            >
                                <i :class="i.icon"></i>
                                <span class="hide-menu">{{ i.name }}</span>
                                <span class="premium pre">
                                    <img src="@/core/assets/img/premium.png" alt="premium">
                                </span>
                            </a>
                            <div
                                v-else-if="i.type == 2"
                                class="sidebar-link has-arrow waves-effect waves-dark"
                                v-on:click="activeMenuMoneyIn"
                                v-bind:class="{ active: i.mActive }"
                                aria-expanded="false"
                            >
                                <i :class="i.icon"></i>
                                <span class="hide-menu">{{ i.name }} </span>
                            </div>
                            <div
                                v-else-if="i.type == 3"
                                class="sidebar-link has-arrow waves-effect waves-dark"
                                v-on:click="activeMoneyOut"
                                v-bind:class="{ active: i.mActive }"
                                aria-expanded="false"
                            >
                                <i :class="i.icon"></i>
                                <span class="hide-menu">{{ i.name }} </span>
                            </div>
                            <div
                                v-else-if="i.type == 4"
                                class="sidebar-link has-arrow waves-effect waves-dark"
                                v-on:click="activeSmartOffice(i)"
                                v-bind:class="{ active: i.mActive }"
                                :aria-expanded="i.mActive"
                            >
                                <i :class="i.icon"></i> 
                                <span class="hide-menu">{{ i.name }} </span>
                            </div>

                            <ul
                                aria-expanded="false"
                                :class="['collapse ', (i.mActive==true) ? 'first-level in' : '']"
                            >
                                <template v-for="(item, idx) in i.items">
                                    <li
                                        class="sidebar-item"    
                                        v-bind:key="idx"
                                        v-if="ocultarMenu.indexOf(item.m_id)==-1"
                                    >
                                        <a href="#"
                                            @click.prevent="optPremium()"
                                            exact-active-class="active"
                                            class="sidebar-link optPremium"
                                            v-bind:class="{ noneMenuOp : (ocultarMenu.indexOf(item.m_id)>-1 && optionsPremium.indexOf(item.m_id)>-1)}"
                                            v-if="(optionsPremium.indexOf(item.m_id)>-1 && !PlanPremium)"
                                        >
                                            <span class="hide-menu">
                                                <span>{{ item.name }}</span>
                                                <!-- <span v-if="item.m_id==4 || item.m_id==5">
                                                    {{ item.lastname }}
                                                </span> -->
                                            </span>
                                            <span v-if="optionsPremium.indexOf(item.m_id)>-1" class="premium pre">
                                                <i class="far fa-gem"></i>
                                            </span>
                                        </a>
                                        <a href="#"
                                            v-else
                                            :title="(optionsPremium.indexOf(item.m_id)>-1) ? 'Premium' : ''"
                                            @click.prevent="toSubmenu($event, i, item.ruta)"
                                            v-bind:class="['sidebar-link optPremium', (ocultarMenu.indexOf(item.m_id)>-1 && optionsPremium.indexOf(item.m_id)>-1) ? 'noneMenuOp' : '', (activeRoute==item.ruta.name) ? 'active' : '']"
                                        >
                                            <span class="hide-menu">
                                                <span>{{ item.name }}</span>
                                                <!-- <span v-if="item.m_id==4 || item.m_id==5">
                                                    {{ item.lastname }}
                                                </span> -->
                                            </span>
                                            <span v-if="optionsPremium.indexOf(item.m_id)>-1" class="premium pre">
                                                <i class="far fa-gem"></i>
                                            </span>
                                        </a>
                                    </li>
                                </template>
                            </ul>
                        </li>
                    </ul>
                </nav>
                <div class="btnAsideAction" @click="ShowHiddenAside()">
                    <i
                        class="fas fa-chevron-left"
                        v-bind:class="{ min: activarLateral }"
                    ></i>
                </div>
            </div>
        </aside>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ClickOutside from 'vue-click-outside'
export default {
    name: "Aside",
    data() {
        return {
            isMoneyIn: true,
            isMoneyOut: false,
            isSmartOffice: false,
            activarLateral: false,
            winnerWidth: window.innerWidth,
            menuBilling: {},
            indiceIn: 0,
            indiceOut: 0,
            indiceOficce: 0,
            PlanPremium : (window.OrgPlan > 1),
            optionsPremium: [4, 9, 20, 21, 22],
            ocultarMenu: [4, 9],
            showMenuRecInvo: true,
            freePlan: false,
            activeRoute: null,
            viewPRO: false,
        };
    },
    directives: {
        ClickOutside
    },
    created() {
        this.activeRoute = this.$router.currentRoute.name;
        this.PlanPremium = (this.$store.getters['core/Org/getPlanId'] > 1);
        if(this.PlanPremium){
            // activar est pro
            // this.optionsPremium= [3, 4, 9, 20, 21, 22];
            // this.viewPRO=true;
            // desactiva est pro
            this.optionsPremium= [4, 9, 20, 21, 22];
            this.viewPRO=false;
        }
        console.log('created PlanPremium', this.PlanPremium);
        this.freePlan = (this.$store.getters['core/Org/getPlanId'] == 4);
        console.log('created freePlan', this.freePlan);
        if(this.freePlan==true){
            this.PlanPremium =false;
            this.optionsPremium=[2, 4, 5, 6, 7, 8, 9, 10, 11, 19, 20, 21, 22];
        }
        if (
            this.$router.currentRoute.name != "Invoices" &&
            this.$router.currentRoute.name != "Estimates" &&
            this.$router.currentRoute.name != "estpro" &&
            this.$router.currentRoute.name != "InvoicesRec" &&
            this.$router.currentRoute.name != "PaymentsReceived" &&
            this.$router.currentRoute.name != "Expenses" &&
            this.$router.currentRoute.name != "Employees" &&
            this.$router.currentRoute.name != "Vendors" &&
            this.$router.currentRoute.name != "Banking" &&
            this.$router.currentRoute.name != "timesheet" &&
            this.$router.currentRoute.name != "WriteaCheck" &&
            localStorage.getItem("isMoneyIn") != 0 &&
            localStorage.getItem("isMoneyOut") != 0 &&
            localStorage.getItem("isSmartOffice") != 0
        ) {
            this.desactivarMoneys();
        }
        this.getMenuSetting();
        this.estructuraMenu();
        this.validarMenu();
    },
    methods: {
        getMenuSetting() {
            let t = this;
            window.billing
                .post('getMenuSetting', {
                    user_id: window.master_client,
                    org_id: window.localorgdata,
                })
                .then((response) => {
                    console.log(response.data);
                    let r = response.data;
                    if(r.status){
                        t.ocultarMenu = [];
                        for (let i in r.menus) {
                            if(r.menus[i].meta_key == 'menuRecInvo'){
                                if(r.menus[i].meta_value == 0) {
                                    t.ocultarMenu.push(4);//ocultar
                                }else{
                                    t.ocultarMenu.splice(0, 1);//mostrar = position idx, 1 item
                                }
                            }
                            if(r.menus[i].meta_key == 'menuWriteCheck'){
                                if(r.menus[i].meta_value == 0) {
                                    t.ocultarMenu.push(9);//ocultar
                                }else{
                                    t.ocultarMenu.splice(1, 1);//mostrar = position idx, 1 item
                                }
                            }
                        }
                    }
                    console.log('ocultarMenu', t.ocultarMenu);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        optPremium(){
            this.$store.commit('core/session/setShowPopPremium', {show: true, data: []});
        },
        activarMenusDesplegable() {
            if (localStorage.getItem("isMoneyIn") == null) {
                localStorage.setItem("isMoneyIn", 1);
                this.isMoneyIn = true;
            } else {
                this.isMoneyIn = localStorage.getItem("isMoneyIn") != 0;
            }
            if (localStorage.getItem("isMoneyOut") == null) {
                localStorage.setItem("isMoneyOut", 0);
                this.isMoneyOut = false;
            } else {
                this.isMoneyOut = localStorage.getItem("isMoneyOut") != 0;
            }
            if (localStorage.getItem("isSmartOffice") == null) {
                localStorage.setItem("isSmartOffice", 0);
                this.isSmartOffice = false;
            } else {
                this.isSmartOffice = localStorage.getItem("isSmartOffice") != 0;
            }
        },
        estructuraMenu() {
            this.menuBilling = {
                1: {
                    name: "Dashboard",
                    ruta: { name: "Billing" },
                    icon: "fas fa-home",
                    type: 1,
                    m_id: 0,
                    group: 1
                },
                2: {
                    name: "Customer",
                    ruta: { name: "Customer" },
                    icon: "fas fa-user",
                    type: 1,
                    m_id: 1,
                    group: 2
                },
                3: {
                    name: "Money In",
                    icon: "fas fa-dollar-sign",
                    type: 2,
                    group: 3,
                    mActive: this.isMoneyIn,
                    items: {
                        1: {
                            name: "Invoices",
                            ruta: { name: "Invoices" },
                            m_id: 2,
                            group: 3,
                        },
                        2: {
                            // name: (this.viewPRO) ? "Estimates Pro" : "Estimates",
                            // ruta: { name: (this.viewPRO) ? "estpro" : "Estimates" },
                            name: "Estimates",
                            ruta: { name: "Estimates" },
                            m_id: 3,
                            group: 3,
                        },
                        3: {
                            name: "Recurring Inv.",
                            lastname: "Invoices",
                            ruta: { name: "InvoicesRec" },
                            m_id: 4,
                            group: 3,
                        },
                        4: {
                            name: "Payments ",
                            lastname: "received",
                            ruta: { name: "PaymentsReceived" },
                            m_id: 5,
                            group: 3,
                        },
                    },
                },
                4: {
                    name: "Money Out",
                    icon: "fas fa-coins",
                    type: 3,
                    group: 4,
                    mActive: this.isMoneyOut,
                    items: {
                        1: {
                            name: "Expenses",
                            ruta: { name: "Expenses" },
                            m_id: 6,
                            group: 4,
                        },
                        2: {
                            name: "Subcontractors",
                            ruta: { name: "Employees" },
                            m_id: 7,
                            group: 4,
                        },
                        3: {
                            name: "Vendors",
                            ruta: { name: "Vendors" },
                            m_id: 8,
                            group: 4,
                        },
                        4: {
                            name: "Write Check",
                            ruta: { name: "WriteaCheck" },
                            m_id: 9,
                            group: 4,
                        },
                        5: {
                            name: "Banking",
                            ruta: { name: "Banking" },
                            m_id: 10,
                            group: 4,
                        },
                        6: {
                            name: "Timesheet",
                            ruta: { name: "Timesheet" },
                            m_id: 19,
                            group: 4,
                        },
                    },
                },
                5: {
                    name: "Smart Office",
                    icon: "fas fa-building",
                    type: 4,
                    group: 8,
                    mActive: this.isSmartOffice,
                    items: {
                        1: {
                            name: "Task",
                            ruta: { name: "smartO_task" },
                            m_id: 20,
                            group: 8,
                        },
                        2: {
                            name: "Visualizer",
                            ruta: { name: "smartO_visualizer" },
                            m_id: 21,
                            group: 8,
                        },
                        3: {
                            name: "Knowledge",
                            ruta: { name: "smartO_knowledge" },
                            m_id: 22,
                            group: 8,
                        },
                    },
                },
                6: {
                    name: "Reports",
                    ruta: { name: "BillingReport" },
                    icon: "fas fa-chart-line",
                    type: 1,
                    m_id: 11,
                    group: 5,
                },
                7: {
                    name: "Setting",
                    ruta: { name: "Setting" },
                    icon: "fas fa-cog",
                    type: 1,
                    m_id: 12,
                    group: 6,
                },
            };
        },
        validarMenu() {
            let menu = [];
            let appID = this.$store.getters["core/apps/getAppIDactual"]
            let appRolUser = this.$store.getters["core/apps/get_appRolUser"];
            let validAdminApp = appRolUser.filter(r => {
                if(appID==r.app_id && r.rol==1){
                    return r;
                }
            });
            if (window.user_type == 0 || validAdminApp.length>0) {
                menu = this.menuBilling;
                this.indiceIn = 3;
                this.indiceOut = 4;
                this.indiceOficce = 5;
                this.$store.dispatch("ModApps/billing/PermisionUserDash", true);
            } else {
                menu.push(this.menuBilling[1]);
                let name_app = "";
                for (let i in this.$store.getters["core/apps/applistorgs"]) {
                    if (
                        this.$store.getters["core/apps/getAppIDactual"] ==
                        this.$store.getters["core/apps/applistorgs"][i].id
                    ) {
                        name_app = this.$store.getters["core/apps/applistorgs"][i].name;
                    }
                }
                let privilegios = this.$store.getters["core/session/privilegiesSesion"];
                let modulos = privilegios.modulos[name_app];
                console.log('modulos a seleccionar =', modulos);
                let grupoIn = 0;
                let grupoOut = 0;
                for (let i in modulos) {
                    //console.log('ciclo '+i);
                    if (
                        modulos[i][0] == 2 ||
                        modulos[i][0] == 3 ||
                        modulos[i][0] == 4 ||
                        modulos[i][0] == 5
                    ) {
                        grupoIn++;
                    }
                    if (
                        modulos[i][0] == 6 ||
                        modulos[i][0] == 7 ||
                        modulos[i][0] == 8 ||
                        modulos[i][0] == 9 ||
                        modulos[i][0] == 10
                    ) {
                        grupoOut++;
                    }
                    if (modulos[i][0] == 13) {
                        this.$store.dispatch("ModApps/billing/PermisionUserDash", true);
                    } else {
                        this.$store.dispatch("ModApps/billing/PermisionUserDash", false);
                    }
                    for (let m in this.menuBilling) {
                        if (m == 3 || m == 4 || m == 5) {
                            //console.log('item'+m+' =', this.menuBilling[m].items)
                            let SubMoney = {};
                            let subMenus = [];
                            let itemMenu = this.menuBilling[m].items;
                            // if(m==5){console.log('item'+m+' =', this.menuBilling[m].items)}
                            for (let item in itemMenu) {
                                //console.log('comparar menu'+itemMenu[item].m_id+'== modulo '+modulos[i][0]+' grupo '+modulos[i][1], modulos[i]);
                                if (
                                    itemMenu[item].m_id == modulos[i][0] &&
                                    modulos[i][1] == this.menuBilling[m].group
                                ) {
                                    //debugger;
                                    //console.log('agregando subMenus', itemMenu[item])
                                    subMenus.push(itemMenu[item]);
                                }
                            }
                            if (subMenus.length > 0) {
                                //console.log('subMenus', subMenus)
                                let group = 0;
                                if (m == 3) {
                                    group = this.indiceIn;
                                }
                                if (m == 4) {
                                    group = this.indiceOut;
                                }
                                if (m == 5) {
                                    group = this.indiceOficce;
                                }
                                if (group == 0) {
                                    SubMoney.name = this.menuBilling[m].name;
                                    SubMoney.icon = this.menuBilling[m].icon;
                                    SubMoney.type = this.menuBilling[m].type;
                                    SubMoney.group = this.menuBilling[m].group;
                                    SubMoney.mActive = this.menuBilling[m].mActive;
                                    SubMoney.items = subMenus;
                                    menu[m] = SubMoney;
                                    //console.log('agregado'+m+' = ',menu[m], this.menuBilling[m].name)
                                    if (m == 3 && this.indiceIn == 0) {
                                        this.indiceIn = m;
                                    }
                                    if (m == 4 && this.indiceOut == 0) {
                                        this.indiceOut = m;
                                    }
                                    if (m == 5 && this.indiceOficce == 0) {
                                        this.indiceOficce = m;
                                    }
                                } else {
                                    menu[group].items.push(subMenus[0]);
                                    //console.log('agregado'+group+' = ',menu[group].items)
                                }
                                subMenus = [];
                            }
                        } else {
                            //console.log('comparar '+this.menuBilling[m].m_id+'=='+modulos[i][0]);
                            if (this.menuBilling[m].m_id == modulos[i][0]) {
                                menu[m] = this.menuBilling[m];
                                //console.log('agregado'+m+' = ',menu[m])
                            }
                        }
                    }
                }
                this.menuBilling = [];
                //console.log('menu', menu)
                for (let i in menu) {
                    this.menuBilling.push(menu[i]);
                    if (menu[i].type == 2) {
                        this.indiceIn = this.menuBilling.length - 1;
                    }
                    if (menu[i].type == 3) {
                        this.indiceOut = this.menuBilling.length - 1;
                    }
                    if (menu[i].type == 4) {
                        this.indiceOficce = this.menuBilling.length - 1;
                    }
                }
                //console.log('nuevo menu =',this.menuBilling);
                //console.log('grupoIn = '+grupoIn+' grupoOut = '+grupoOut);
                if (grupoIn > 0 && grupoOut > 0) {
                    this.activarMenusDesplegable();
                } else if (grupoIn > 0 && grupoOut == 0) {
                    this.activeMenuMoneyIn();
                } else {
                    //console.log('activeMoneyOut')
                    this.activeMoneyOut();
                }
            }
        },
        activeMoneyOut() {
            this.isMoneyOut = !this.isMoneyOut;
            this.menuBilling[this.indiceOut].mActive = this.isMoneyOut;
            if (this.isMoneyOut) {
                localStorage.setItem("isMoneyOut", 1);
            } else {
                localStorage.setItem("isMoneyOut", 0);
            }
            this.isMoneyIn = false;
            this.menuBilling[this.indiceIn].mActive = this.isMoneyIn;
            localStorage.setItem("isMoneyIn", 0);
        },
        activeSmartOffice(item) {
            this.isSmartOffice = !item.mActive;
            this.menuBilling[this.indiceOficce].mActive = this.isSmartOffice;
            if (this.isSmartOffice) {
                localStorage.setItem("isSmartOffice", 1);
            } else {
                localStorage.setItem("isSmartOffice", 0);
            }
            this.menuBilling[this.indiceOficce].mActive = this.isSmartOffice;
            console.log('SmartOffice', this.menuBilling[this.indiceOficce].mActive)
        },
        activeMenuMoneyIn() {
            this.isMoneyIn = !this.isMoneyIn;
            this.menuBilling[this.indiceIn].mActive = this.isMoneyIn;
            if (this.isMoneyIn) {
                localStorage.setItem("isMoneyIn", 1);
            } else {
                localStorage.setItem("isMoneyIn", 0);
            }
            this.isMoneyOut = false;
            this.menuBilling[this.indiceOut].mActive = this.isMoneyOut;
            localStorage.setItem("isMoneyOut", 0);
        },
        desactivarMoneys() {
            this.isMoneyIn = false;
            this.isMoneyOut = false;
            this.isSmartOffice = false;
            localStorage.setItem("isMoneyIn", 0);
            localStorage.setItem("isMoneyOut", 0);
            localStorage.setItem("isSmartOffice", 0);
        },
        ShowHiddenAside(reload) {
            if(reload == undefined){
                this.activarLateral = !this.activarLateral;
                this.$store.commit("ModApps/billing/setVisibleAside", {'vista':'aside', 'aside':this.activarLateral});
            }else{
                //console.log('orden desde una de las vista, ocultar siempre')
                this.activarLateral = true;
            }
                this.$emit("activarLateral", this.activarLateral);
                this.desactivarMoneys();
        },
        toSubmenu(e, item, toRoute){
            let t = this;
            console.log('toSubmenu',e, toRoute)
            if (window.innerWidth < 1100) {
                if(item.type==2){
                    t.activeMenuMoneyIn();
                }
                if(item.type==3){
                    t.activeMoneyOut(item);
                }
                if(item.type==4){
                    t.activeSmartOffice(item);
                }
            }
            if(t.$router.currentRoute.name !=toRoute.name){
                t.$router.push({name: toRoute.name});
            }else{
                console.log('ya estoy en la ruta ', toRoute.name)
            }
        },
    },
    mounted: function () {
        if (this.winnerWidth < 1050) {
            this.ShowHiddenAside();
        }
    },
    watch :{
        $route (to, from){
            console.log('$router.currentRoute.name', this.$router.currentRoute.name)
            this.activeRoute = this.$router.currentRoute.name;
        },
        getPlanId() {    
            this.PlanPremium = (this.$store.getters['core/Org/getPlanId'] > 1);
            console.log('watch PlanPremium', this.PlanPremium);
        },
        accitonPusher: function () {
            if (this.accitonPusher != undefined) {
                let p = this.accitonPusher;
                if (p.action == "actualizarMenu") { 
                    if(window.master_client == p.user_id && window.localorgdata == p.org_id){
                        this.getMenuSetting();
                    }
                }
            }
        },
        getVisibleAside: function(){
            let t =this; 
            if(t.getVisibleAside!=null){
                if(t.getVisibleAside.vista!='aside'){
                    console.log('aside', t.getVisibleAside.vista)
                    t.ShowHiddenAside(1);
                }
            }
        },
    },
    computed: {     
        ...mapGetters("core/Org", { getPlanId: "getPlanId"}),
        ...mapGetters("core", {accitonPusher: "get_accitonPusher"}),
        ...mapGetters("ModApps/billing", {getVisibleAside: "getVisibleAside"}),
    }
};
</script>
<style lang="scss" scoped>
.left-sidebar {
    position: fixed;
}
aside.left-sidebar,
aside.left-sidebar.min
.scroll-sidebar
nav.sidebar-nav
ul#sidebarnav
li.sidebar-item:hover
ul.collapse.first-level,
aside.left-sidebar.min
.scroll-sidebar
nav.sidebar-nav
ul#sidebarnav
li.sidebar-item:hover
ul.collapse.first-level.in,
.sidebar-nav ul .sidebar-item
{
    width: 160px;
}
.btnAsideAction {
    position: absolute;
    bottom: -18px;
    top: initial;
    left: 0;
    width: 100%;
    height: 40px;
    background-color: #343435;
    cursor: pointer;
    padding: 10px 4px 4px 4px;
    line-height: 21px;
    text-align: center;
    color: #fff;
}
aside.left-sidebar.min {
    width: 60px;
    z-index: 100;
}
aside.left-sidebar.min .scroll-sidebar {
    height: 100%;
}
aside.left-sidebar.min .scroll-sidebar .btnAsideAction {
    bottom: 0%;
}
aside.left-sidebar.min .scroll-sidebar .btnAsideAction i:before {
    content: "\f054";
}
aside.left-sidebar.min .scroll-sidebar nav.sidebar-nav ul#sidebarnav li.sidebar-item,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item
    ul.collapse.first-level
    li.sidebar-item {
    width: 60px;
    transition: all 0.5s ease-out;
    position: relative;
}

aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    ul.collapse.first-level,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    ul.collapse.first-level.in {
    position: absolute;
    top: 0px;
    left: 55px;
}

aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item
    a.sidebar-link {
}
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item
    a.sidebar-link
    span.hide-menu,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item
    div.sidebar-link
    span.hide-menu,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item
    ul.collapse.first-level,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item
    ul.collapse.first-level.in {
    display: none;
}
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    ul.collapse.first-level,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    ul.collapse.first-level.in,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    ul.collapse.first-level
    li.sidebar-item
    a.sidebar-link
    span.hide-menu {
    display: block;
}
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    ul.collapse.first-level
    li.sidebar-item
    a.sidebar-link {
    border-left: 2px solid transparent;
}
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    a.sidebar-link,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    div.sidebar-link.has-arrow.waves-effect.waves-dark,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    div.sidebar-link.has-arrow.waves-effect.waves-dark.active {
    border-left: 2px solid #137eff;
    opacity: 1;
}
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    div.sidebar-link,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    ul.collapse.first-level {
    background: #191919;
}
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item
    > .has-arrow::after {
    transition: all 0.5s ease-out;
}
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item
    > .has-arrow::after {
    -webkit-transform: rotate(135deg) translate(0, -50%) !important;
    transform: rotate(135deg) translate(0, -50%) !important;
}

@media (max-width: 1024px) {
    .sidebar-nav ul .sidebar-item .sidebar-link {
        padding: 8px 8px;
    }
}
.noneMenuOp{
    display: none !important;
}
.showRec{
    display: list-item !important;
}
span.hide-menu {
    font-size: 14px;
}
span.premium {
    width: 28px;
    opacity: 0.8;
}
.sidebar-nav ul .sidebar-item a.sidebar-link.optPremium:hover, .sidebar-nav ul .sidebar-item a.sidebar-link.optPremium:hover span.premium{
    opacity: 1;
}
.sidebar-nav ul .sidebar-item a.sidebar-link.optPremium span.hide-menu {
    opacity: 0.8;
}
.sidebar-nav ul .sidebar-item a.sidebar-link.optPremium:hover span.hide-menu {
    opacity: 1;
}
span.hide-menu span{
    display: block;
    line-height: 16px;
}
</style>
