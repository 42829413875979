<template>
    <div class="col-lg-5 col-md-6">
        <div class="form-group">
            <label for="nameEstInput">Estimate Name <small>(Optional)</small></label>
            <input
                type="text"
                :class="['form-control', (disabledSteps.customer) ? 'disabled' : '']"
                :disabled="disabledSteps.customer"
                id="nameEstInput"
                maxlength="60"
                v-model="document_name"
                placeholder="Document name"
                @change="updateData"
                />
        </div>
        <div class="form-group showtooltip">
            <!-- <label for="job_information" v-if="jobinfomationcheck">{{ jobinfomationtitle }} <small>(Optional)</small></label> -->
            <textarea v-model="job_information"
                id="job_information"
                :class="['form-control', (disabledSteps.customer) ? 'disabled' : '']"
                maxlength="200"
                @keypress="noEnter"
                :disabled="!jobinfomationcheck || disabledSteps.customer"
                :placeholder="jobinfomationtitle+': '+((jobinfomationcheck) ? 'Let your client know what this Estimate is for:' : 'Field disabled, set in settings')"
                @change="updateData"
            ></textarea>
            <span class="maxSize">
                {{ (job_information!=null) ? job_information.length : 0 }}/200
            </span>
            <!-- <span class="tooltip">prueba de tootlip</span> -->
        </div>
    </div>
</template>
<script>
export default {
    props: [
        "controlLoad",
        "propdisabledSteps",
        "propdocument_name",
        "propjob_information",
    ],
    data() {
        return {
            disabledSteps: this.propdisabledSteps,
            document_name: this.propdocument_name,
            job_information: this.propjob_information,
            jobinfomationcheck: true,
            jobinfomationtitle: "Job information",
        }
    },
    created() {
        let t = this;
    },
    watch : {
        controlLoad: function(){
            let t = this;
            if(this.controlLoad){
                t.getEstimateSetting();
            }
        },
    },
    methods: {
        getEstimateSetting() {
            let t = this;
            window.billing
            .get("estimates/setting/" + window.localuserdata)
            .then((response) => {
                console.log('getEstimateSetting', response.data.result);
                if (response.data.result.length) {
                    let r = response.data.result[0];
                    t.jobinfomationcheck = r.jobinfomationcheck;
                    t.jobinfomationtitle = r.jobinfomationtitle;
                    t.job_information = r.jobinfomationtext;
                    t.updateData()
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        noEnter(e){
            var code = (e.which) ? e.which : e.keyCode;
            if(code == 13){
                return e.preventDefault();
            }
        },
        updateData(){
            let t = this;
            t.$emit('updateProps', {
                "document_name": t.document_name,
                "job_information": t.job_information,
            })
        },
    },
};
</script>
